//切位订单列表
import Q from 'q';

import web_common_buyerTourCutOrder_orderTeamDetails from '@/lib/data-service/default/web_common_buyerTourCutOrder_orderTeamDetails';
import ccbBankPay from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/call_ccb_pc_data_interface'
import toPayment from '@/lib/data-service/default/toPayment';


import PaymentMethodSelector from "@/lib/common-service/getPayModule";


export default {
  components: {
    PaymentMethodSelector,
  },
  data() {
    return {
      detailsData: {},
      dialogVisible: false,
      payRadio: ''
    };
  },
  methods: {
    req_order_details(params) {
      return Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_orderTeamDetails(params);
        })
    },
    initialize(query) {
      const params = {
        id: query.id
      };
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_order_details(params);
        })
        .then(function (res) {
          _this.detailsData = res.result;
          // _this.newPaySubmit();
          _this.newPaySubmit(_this.detailsData.noTailMoneyAmount);
        })
    },
    newPaySubmit(orderAmount) {
      const __this = this;
      this.$nextTick(() => {
        this.$refs.aPaymentMethodSelector.init({
          // get_order_data() {
          //   // console.log(__this.$route.query.id)
          //   // const order_data = {
          //   //   businessType: 2,
          //   //   orderId: __this.$route.query.id, //从订单详情接口获取
          //   //   orderType: 0, //从订单详情接口获取
          //   //   orderAmount: orderAmount,//orderAmount
          //   //   subject: __this.detailsData.productName, //产品名称,必填
          //   // };

          //   const order_data = {
          //     companyId: __this.detailsData.companyId,
          //     clubId: __this.detailsData.clubId,
          //     orderNo: __this.detailsData.orderNo,
          //     businessContent: __this.detailsData.businessContent,
          //     subject: __this.detailsData.productName,
          //     orderAmount: orderAmount,
          //   };

          //   return Q.resolve(order_data);
          // },
          situationParameter: {
            "000": {
              companyId: __this.detailsData.companyId,
              clubId: __this.detailsData.clubId,
              orderNo: __this.detailsData.orderNo,
              businessContent: __this.detailsData.businessContent,
              subject: __this.detailsData.productName,
              orderAmount: orderAmount,
              count_down: {
                enable: false,
                // pay_remaining_seconds: 0
              }
            }
          },
          //支付成功事件处理方法
          pay_successful_event_handler() {
            // __this.dialogVisible = true
            this.$router.go(-1)
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            __this.dialogVisible = true
          },
        });
      })
    },
    paySubmit(value, bankId) {
      let cutOrderNo = this.$route.query.cutOrderNo
      let path = `/distributor/tour/back/cut-order-details?cutOrderNo=${cutOrderNo}`
      let _this = this
      let params = {
        subject: this.detailsData.productName,
        orderId: this.$route.query.id,
        businessType: 1,
        issuerId: bankId.bank_entity.bankCode || '',
        orderNo: this.detailsData.orderNo,
        orderType: '0',
        paymentChannel: this.payRadio,
      }
      this.payRadio === '4' ? (params.password = value, payment()) : payment()

      function payment() {
        toPayment(params).then(res => {
          if (res.code === _this.SUCCESS_CODE) {
            if (_this.payRadio === '4') {
              _this.$router.push({
                name: 'pay-success-message',
                query: {
                  pathUrl: String(path)
                },
                params: { refresh: 1 }
              })
              return
            }

            if (Object.keys(bankId.bank_entity).length > 0) {
              ccbBankPay(res.resultMap)
              return _this.dialogVisible = true
            }
            let {
              href
            } = _this.$router.resolve({
              name: "air-pay-page",
              query: {
                data: res.htmlStr
              }
            })
            _this.payRadio === '5' ?
              window.open(res.htmlStr) : _this.payRadio === '2' ?
                (window.open(href, '_blank')) : ''
          }
        })
      }
    },
    go_back() {
      this.$router.back(-1);
    }
  },
  watch: {

  },
  mounted() {

  },
  activated() {
    this.initialize(this.$route.query);
  }
}
